var columns = [{
  title: '序号ID',
  dataIndex: 'id',
  key: 'id',
  width: '10%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '用户昵称',
  dataIndex: 'username',
  key: 'username',
  width: '15%',
  scopedSlots: {
    customRender: 'username'
  },
  types: [1, 4]
}, {
  title: '设备ID',
  dataIndex: 'deviceid',
  key: 'deviceid',
  width: '15%',
  scopedSlots: {
    customRender: 'deviceid'
  },
  types: [1, 2, 4]
}, {
  title: '地址IP',
  dataIndex: 'ipaddress',
  key: 'ipaddress',
  width: '15%',
  scopedSlots: {
    customRender: 'ipaddress'
  },
  types: [1, 3, 4]
}, {
  title: '关联邮箱',
  dataIndex: 'email',
  key: 'email',
  width: '15%',
  scopedSlots: {
    customRender: 'email'
  },
  types: [1, 4]
}, {
  title: '添加时间[UTC]',
  dataIndex: 'addtime',
  key: 'addtime',
  width: '15%',
  sorter: false,
  scopedSlots: {
    customRender: 'addtime'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: '15%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };