var searchKeys = [{
  key: "username",
  label: "账号昵称",
  placeholder: "请输入账号昵称",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "email",
  label: "账号关联邮箱",
  placeholder: "请输入账号关联邮箱",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "deviceid",
  label: "设备ID",
  placeholder: "请输入设备ID",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "ipaddress",
  label: "IP地址",
  placeholder: "请输入IP地址",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "date",
  label: "添加时间[UTC]",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "结束时间"],
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };