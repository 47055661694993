var logColumns = [{
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '操作',
  dataIndex: 'typename',
  key: 'typename',
  scopedSlots: {
    customRender: 'typename'
  }
}, {
  title: '操作人',
  dataIndex: 'createusername',
  key: 'createusername',
  scopedSlots: {
    customRender: 'createusername'
  }
}];
export { logColumns };